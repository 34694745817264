import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Spinner, Table } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";

const Contractor = () => {
  const userState = useSelector(state => state.loginData.userData);
  const financialYear = useSelector(state => state.activeData.activeMenu.activeTab)
  const navigate = useNavigate();

  // Variables for Zone & Gat LIST ------------
  const [zoneList, setZoneList] = useState([]);
  const [allGatList, setAllGatList] = useState([]);

  //Varaibles for Filtering Gat depending on Zone ----------
  const [filterGat, setFilterGat] = useState([]);

  // Variables for Zone & Gat DATA -------------
  const [zone, setZone] = useState("");
  const [zoneB, setZoneB] = useState('');
  const [gat, setGat] = useState("");

  // Variables for Zone-Gat Visitors Data ------------
  const [contractor, setContractor] = useState([]);

  const [thead, setThead] = useState("false");

  // Variables for Errors -------------
  // const [enable, setEnable] = useState(false);
  const SearchError = () => toast.error("Please select any zone/gat");
  const [loader, setLoader] = useState(false);

  const [searchMobile, setSearchMobile] = useState("");
  const [searchMobileErr, setSearchMobileErr] = useState(false);

  // property found
  // const [propertyFound, setPropertyFound] = useState([]);
  // const [propertyNotFound, setPropertyNotFound] = useState([]);

  useEffect(() => {
    getZoneList();
    getGatList();
    getAllContractors();
  }, []);

  useEffect(() => {
    getFiltertedGat();
    setGat("");
  }, [zone]);

  const getFiltertedGat = () => {
    const result =
      allGatList !== undefined && allGatList.length > 0
        ? allGatList.filter((row) => row.zonekey == zone)
        : "";
    setFilterGat(result);
  };

  // useEffect(()=>{
  //     getZoneGat();
  // },[zone,gat])

  // Function for Fetching ZONE LIST --------------------
  const getZoneList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/zoneData/getZoneData`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        setZoneList(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };

  // Function for Fetching GAT LIST --------------------
  const getGatList = async () => {
    await fetch(process.env.REACT_APP_BASE_URL + `/gatData/getGatData`, {
      method: "POST",
    })
      .then((res) => res.json())
      .then((response) => {
        setAllGatList(response.data);
        setFilterGat(response.data);
      })
      .catch((err) => {
        console.log("Error While Fetching Zone List", err);
      });
  };

  // Function for Fetching ZONE & GAT DATA --------------------

  const getAllContractors = async () => {
    setLoader(true);
    await fetch(process.env.REACT_APP_BASE_URL + "/dailyReport/getAllDataReport", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ 
        searchDate: visitDate,
        financialYear : financialYear }),
    }).then((res) => res.json()).then((response) => {
      setContractor(response);
      setThead("true");
      setLoader(false);
    }).catch((err) => {
      console.log("Err while Daily Report", err);
      setLoader(false);
    })

    //   await fetch(process.env.REACT_APP_BASE_URL + "/user/getcontractors", {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({ zonekey: zone, gatkey: gat }),
    //   })
    //     .then((res) => res.json())
    //     .then((response) => {
    //       console.log("RESPOSNE==>112", response.data);
    //       setContractor(response);
    //       setLoader(false);
    //     })
    //     .catch((err) => {
    //       console.log("Error while Fetching Visitor Zone/Gat", err);
    //       setLoader(false);
    //     });

  }

  const getContractors = async () => {
    if (!zone && !gat) {
      SearchError();
    } else {
      setLoader(true);
      setThead("false");
      await fetch(process.env.REACT_APP_BASE_URL + "/user/getcontractors", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          zonekey: zone, 
          gatkey: gat,
          financialYear : financialYear }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.message === "No Contractor Found") {
            setLoader(false);
            setContractor(response);
          } else {
            setContractor(response);
            setLoader(false);
          }
        })
        .catch((err) => {
          console.log("Error while Fetching Visitor Zone/Gat", err);
        });
    }
  };

  const validateMobile = () => {
    let mobileValid = true;
    if (searchMobile.length === 0 || searchMobile.length < 10) {
      mobileValid = false;
      setSearchMobileErr(true);
    }
    return mobileValid;
  };

  const searchByMobile = async () => {
    setLoader(true);
    setThead("false");
    if (validateMobile()) {
      await fetch(process.env.REACT_APP_BASE_URL + "/user/getcontractorbymobile",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ 
            contactNo: searchMobile,
            financialYear : financialYear }),
        }
      ).then((res) => res.json())
        .then((response) => {
          if (response.message === "Contractor found") {
            console.log("Response by search Mobile", response);
            setContractor(response);
            setLoader(false);
          } else {
            setContractor(response);
            setLoader(false);
          }
        });
    } else {
      setLoader(false);
    }
  };

  // console.log("======>", contractor);

  // Getting Contractors by date -------------
  const [visitDate, setVisitDate] = useState("2023-05-01");
  const [visitDateB, setVisitDateB] = useState('');
  const [visitDateErr, setVisitDateErr] = useState(false);

  // Validating Visit Date is entered or Not ? -------------
  const validateDate = () => {
    let dateValid = true;
    if (visitDate.length === 0) {
      dateValid = false;
      setVisitDateErr(true);
    }
    return dateValid;
  };

  const searchByDate = async () => {
    setLoader(true);
    if (validateDate()) {
      await fetch(process.env.REACT_APP_BASE_URL + "/dailyReport/getAllDataReport", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          searchDate: visitDate, 
          financialYear : financialYear }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.status === 200) {
            setContractor(response);
            setLoader(false);
          } else {
            setContractor(response);
            setLoader(false);
          }
        });
    } else {
      setLoader(false);
    }
  };

  const [zoneErr, setZoneErr] = useState(false);
  const [dateErr, setDateErr] = useState(false);

  const validateZoneDate = () => {
    let valid = true;
    if (zoneB.length === 0) {
      setZoneErr(true);
      valid = false;
    }
    if (visitDateB.length === 0) {
      setDateErr(true);
      valid = false;
    }
    return valid;
  }
  const searchByZoneDate = async () => {
    setLoader(true);
    setThead(false);
    if (validateZoneDate()) {
      await fetch(process.env.REACT_APP_BASE_URL + '/user/getcontractors', {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ 
          zonekey: zoneB, 
          visitDate: visitDateB,
          financialYear : financialYear })
      }).then((res) => res.json()).then((response) => {
        setContractor(response);
        setLoader(false);
      }).catch((err) => {
        console.log("Err while search Zone/Date", err);
      })
    }
    else {
      setLoader(false);
    }
  }

  return (
    <div className="visitReport">
      <ToastContainer />
      <Container>
        <Row className="justify-content-center mt-3">
          <Col md={6} className="searchCard">
            <div className="label mb-2 mx-auto"><p>Search by zone/gat</p></div>
            <Row className="d-flex justify-content-center">
              <Col md={4} className="mb-3 mb-md-0">
                {/* {
                  login.usertype==="zonalofficer" ? <Form.Control placeholder="Role" name="role" value={login.zonename} disabled/> : */}
                <Form.Select value={zone} onChange={(e) => setZone(e.target.value)}>
                  <option value="" selected disabled> Select Zone</option>
                  {zoneList !== undefined && zoneList.length > 0 ? (
                    zoneList.map((row) => {
                      return (
                        <option value={row.zonekey}>{row.zonename + "-" + row.zoneno}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>

              <Col md={4} className="mb-3 mb-md-0">
                <Form.Select value={gat} onChange={(e) => setGat(e.target.value)}>
                  <option value="" selected disabled>
                    Select Gat
                  </option>
                  {filterGat !== undefined && filterGat.length > 0 ? (
                    filterGat.map((row) => {
                      return (
                        <option value={row.gatkey}>{row.mar_gatname}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
              </Col>

              <Col md={2} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn btn-submit" onClick={getContractors}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>
            </Row>

          </Col>
        </Row>
        {/* 
            <Col md={4} className='mt-0 mb-4'>
              <div className='d-flex'>
                <Form.Control className='me-3' placeholder="Search by mobile number" value={searchMobile} name='contactNo' onChange={(e) => {
                                if (!e.target.value.match(/[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/) && e.target.value.length <= 10) {
                                    setSearchMobile(e.target.value);
                                }
                                e.target.value.length > 0 ? setSearchMobileErr(false) : setSearchMobileErr(true)
                            }} />
                           
                <Button className='btn-submit' onClick={searchByMobile}><Icon.Search className='me-2' size={14}/>Search</Button>
                </div>
                {searchMobileErr ? <p>Please enter valid mobile number</p> : null}
                
            </Col> */}


        <Row className="justify-content-center mt-4">
          <Col md={4} className="mt-0 mb-4 mb-md-0 searchCard">
            <div className="label mb-2 mx-auto"><p>Search by mobile</p></div>
            <div className="d-flex mt-1">
              <Form.Control
                className="me-3"
                placeholder="Enter mobile number"
                value={searchMobile}
                name="contactNo"
                onChange={(e) => {
                  if (
                    !e.target.value.match(
                      /[A-Za-z+@#$&%!~=*./\s/g/\-/?<>,"/]/
                    ) &&
                    e.target.value.length <= 10
                  ) {
                    setSearchMobile(e.target.value);
                  }
                  e.target.value.length > 0
                    ? setSearchMobileErr(false)
                    : setSearchMobileErr(true);
                }}
              />

              <Button className="btn-submit" onClick={searchByMobile}>
                <Icon.Search className="me-2" size={14} />
                Search
              </Button>
            </div>
            {searchMobileErr ? <p>Please enter valid mobile number</p> : null}
          </Col>

          <Col md={{ span: 4, offset: 1 }} className="searchCard">
            <div className="label mb-2 mx-auto"><p>Search by date</p></div>
            <div className="d-flex mt-1">
              <Form.Control
                type="date"
                name="visitDate"
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) => {
                  setVisitDate(moment(e.target.value).format("YYYY-MM-DD"));
                }}
              />
              <Button className="btn-submit ms-3" onClick={searchByDate}>
                <Icon.Search className="me-2" size={14} />
                Search
              </Button>
            </div>
            {visitDateErr ? <p>Please select any date</p> : null}
          </Col>
        </Row>

        {/* --------- Search by zone and Gat ----------- */}

        <Row className="mt-4 justify-content-center">
          <Col md={7} className="searchCard">
            <div className="label mb-2 mx-auto"><p>Search by zone/gat</p></div>
            <Row>
              <Col md={4} className="mb-3 mb-md-0">
                <Form.Select value={zoneB} onChange={(e) => setZoneB(e.target.value)}>
                  <option value="" selected disabled> Select Zone</option>
                  {zoneList !== undefined && zoneList.length > 0 ? (
                    zoneList.map((row) => {
                      return (
                        <option value={row.zonekey}>{row.zonename + "-" + row.zoneno}</option>
                      );
                    })
                  ) : (
                    <option value="">No Data Found</option>
                  )}
                </Form.Select>
                {zoneErr ? <p>Please select any zone</p> : null}
              </Col>

              <Col md={4} className="mb-3 mb-md-0">
                <Form.Control
                  type="date"
                  name="visitDate"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) => {
                    setVisitDateB(moment(e.target.value).format("YYYY-MM-DD"));
                  }}
                />
                {dateErr ? <p>Please select any date</p> : null}
              </Col>

              <Col md={4} className="d-flex justify-content-md-start justify-content-center">
                <Button className="btn-submit ms-3" onClick={searchByZoneDate}>
                  <Icon.Search className="me-2" size={14} />Search
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center w-100 mt-4">
          <Col
            md={4}
            className="d-flex justify-content-center align-items-center"
          >
            <h5 className="mb-0 me-3">
              <strong>Total Visits -</strong> {contractor.count}
            </h5>
            {/* <Button className="btn-submit" onClick={() => getAllContractors()}>
              <Icon.ArrowClockwise size={25} />
            </Button> */}
          </Col>
        </Row>

        <Row className="visitors mt-2">
          <div className="container tbl-container p-0">
            <div className="row tbl-fixed p-0" style={{ maxHeight: '65vh' }}>
              <Table responsive="lg" hover>
                <thead>
                  <tr>
                    <th>Sr.</th>
                    <th style={{ width: 'maxContent' }}>Name</th>
                    <th>Mobile No.</th>
                    <th>Zone</th>
                    <th>Gat</th>
                    <th>Visit Count<br></br>(Found)</th>
                    <th> Visit Count<br></br>(Not Found)</th>
                    <th>Total Visits</th>
                    {thead === "true" ?
                      <>
                        <th>Mobile<br></br>Updated</th>
                        <th>Alt. Mob<br></br>updated</th>
                        <th>Address<br></br>Updated</th>
                        <th>Alt. Address<br></br>Updated</th>
                        <th>Meeting<br></br>Persons</th>
                        <th>LatLong<br></br> Found</th>
                        <th>LatLong<br></br>Not Found</th>
                      </> : ""
                    }
                  </tr>
                </thead>

                {loader ? (
                  <Spinner variant="primary" className="mt-2" />
                ) : (
                  <tbody>
                    {contractor.data !== null &&
                      contractor.data !== undefined &&
                      contractor.data.length > 0
                      ? contractor.data.map((row, index) => {
                        return (
                          <tr onClick={() =>{ 
                            localStorage.setItem('visitor', JSON.stringify(row))
                            navigate("/dashboard/visits")}}
                            style={{ textAlign: "center", cursor: "pointer" }}>
                            <td>{index + 1}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{row?.name}</td>
                            <td>{row?.contactNo}</td>
                            <td style={{ whiteSpace: 'nowrap' }}>{row?.zonename}</td>
                            <td>{row?.gatname.toString()}</td>
                            <td>{row?.visitCountPF}</td>
                            <td>{row?.visitCountPNF}</td>
                            <td>{row?.totalCount}</td>
                            {thead === "true" ?
                              <>
                                <td>{row?.mobileUpdatedCount}</td>
                                <td>{row?.alternateMobileUpdatedCount}</td>
                                <td>{row?.addressUpdatedCount}</td>
                                <td>{row?.altAddressUpdatedCount}</td>
                                <td>{row?.meeetingPersonCount}</td>
                                <td>{row?.latLongFound}</td>
                                <td>{row?.latLongNotFound}</td></> : ""}
                          </tr>
                        );
                      })
                      : "No contractors data found"}
                  </tbody>
                )}
                {/* <div className="contractor-footer">
              <div className="records">
                <h5>
                  Visit Count<br></br>(Property Found) -
                </h5>
                <h5>
                  Visit Count<br></br>(Property Not Found) -
                </h5>
              </div>
            </div> */}
                {/* </Table> */}
                <tfoot>
                  <tr>
                    <th>Total</th>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: 'center' }}><strong>{contractor.totalVisitPF}</strong></td>
                    <td style={{ textAlign: 'center' }}><strong>{contractor.totalVisitPNF}</strong></td>
                    <td style={{ textAlign: 'center' }}><strong>{contractor.count}</strong></td>

                    {thead === "true" ?
                      <>
                        <td style={{ textAlign: 'center' }}><strong>{contractor.totalMobileUpdatedCount}</strong></td>
                        <td style={{ textAlign: 'center' }}><strong>{contractor.totalAlternateMobileUpdatedCount}</strong></td>
                        <td style={{ textAlign: 'center' }}><strong>{contractor.totalAddressUpdatedCount}</strong></td>
                        <td style={{ textAlign: 'center' }}><strong>{contractor.totalAlternateMobileUpdatedCount}</strong></td>
                        <td style={{ textAlign: 'center' }}><strong>{contractor.totalMeeetingPersonCount}</strong></td>
                        <td style={{ textAlign: 'center' }}><strong>{contractor.totalLatLongFound}</strong></td>
                        <td style={{ textAlign: 'center' }}><strong>{contractor.totalLatLongNotFound}</strong></td>
                      </> : ""}
                  </tr>
                </tfoot>
              </Table>
            </div>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Contractor;
