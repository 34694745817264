import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Form, Card,Table,Button } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { setActiveKey } from "../../Feature/Sidebar/ActiveKeySlice";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import Pagination from "../Common/Pagination";


const ViewContractorReport = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userState = useSelector((state) => state.loginData.userData);
  const location = useLocation();
  // const contractor = location.state.contractor;
  const contractor = JSON.parse(localStorage.getItem('contractor'));
  
  const [contractorWiseReport,setContractorWiseReport] = useState([])

  // States for Pagination ----------
  const [pageNo, setPageNo] = useState(1);
  const [docPerPage, setDocPerPage] = useState(50);
  const [noOfPages, setNoOfPages] = useState(0);

  // function for pagination

  const handlePageChange = (pageNumber) => {
    setPageNo(pageNumber);
  };

  const handleDocsPerPage = (docsPageProp) => {
    setPageNo(1);
    setDocPerPage(docsPageProp);
  };

  useEffect(() => {
    getContractorWiseData();
  }, [pageNo, docPerPage]);

  const getContractorWiseData = async () => {
    await fetch(
      process.env.REACT_APP_BASE_URL +
        "/billDistribution/getAllBillDistributionsPGN",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          visitingPersonContactNo: contractor.mobile,
          documentsPerPage: docPerPage,
          page: pageNo,
        }),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if (response.status === 200) {
          setContractorWiseReport(response)
          setNoOfPages(response.noOfPages)
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="visitReport">
      <ToastContainer />
      <Row className="mb-2 mt-4">
        <Col md={2}>
          <Button className="backButton" onClick={() => window.history.back()}>
            <Icon.ArrowLeft size={20} className="me-2" /> Back
          </Button>
        </Col>
      </Row>
      <Row className="justify-content-center mb-3">
          <Col md={5}>
            <Card className="searchCard mt-4">
              <Form.Label className="searchLabel">Contractor Details</Form.Label>
              <div className="d-flex flex-column align-items-start">
                <h5>
                  Name:- <strong>{contractor.name}</strong>
                </h5>
                <h5>
                  Mobile:- <strong>{contractor.mobile}</strong>
                </h5>
              </div>
            </Card>
          </Col>
        </Row>
      <h5>Property Found Reports:- </h5>
      <Row className="visitors mt-2">
        <div className="container tbl-container p-0">
          <div className="row tbl-fixed p-0" style={{ maxHeight: "65vh" }}>
            <Table responsive="lg" hover>
              <thead>
                <th>Sr No.</th>
                <th>Propety Code</th>
                <th>Propety Owner Name</th>
                <th>Propety Owner Contact No</th>
                <th>Propety Address</th>
                <th>Zone Name</th>
                <th>Gat Name</th>
                <th>Visit Person Name</th>
                <th>Visit Date</th>
                <th>Action</th>
              </thead>
              <tbody>
                {contractorWiseReport?.data && contractorWiseReport.data.length > 0 ? (
                  contractorWiseReport.data.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {pageNo !== 1 ? (
                            <> {index + 1 + docPerPage * (pageNo - 1)}</>
                          ) : (
                            <>{index + 1}</>
                          )}
                        </td>
                        <td>{row?.propertyCode ? row?.propertyCode : "-"}</td>
                        <td>{row?.propertyName ? row?.propertyName : "-"}</td>
                        <td>
                          {row?.propertyContactNo
                            ? row?.propertyContactNo
                            : "-"}
                        </td>
                        <td>
                          {row?.propertyAddress ? row?.propertyAddress : "-"}
                        </td>
                        <td>{row?.zonename ? row?.zonename : "-"}</td>
                        <td>{row?.gatname ? row?.gatname : "-"}</td>
                        <td>
                          {row?.visitingPersonName
                            ? row?.visitingPersonName
                            : "-"}
                        </td>
                        <td>
                          {row?.visitDate
                            ? moment(row?.visitDate).format("DD MMM YYYY")
                            : "-"}
                        </td>
                        <td className="text-center">
                          <Icon.Eye
                            style={{ cursor: "pointer" }}
                            size={25}
                            onClick={() =>
                              navigate("/dashboard/view-visit-report", {
                                state: {
                                  report: row,
                                  comesFrom: "Visit Report",
                                },
                              })
                            }
                          />
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>No data found</tr>
                )}
              </tbody>
            </Table>
          </div>
          <Row>
            <Col md={4} className="d-flex align-items-center mt-3 mt-md-0">
              <h6 className="text-start mb-0">
                <Icon.People className="me-2" />
                Total -{" "}
                <strong>{contractorWiseReport?.count ? contractorWiseReport?.count : 0}</strong>
              </h6>
            </Col>
            <Col md={8} className="d-flex justify-content-end">
              <Pagination
                currentPage={pageNo}
                totalPages={noOfPages}
                onPageChange={handlePageChange}
                onDocsPerPage={handleDocsPerPage}
                docsPerPage={docPerPage}
              />
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default ViewContractorReport;
