import React, { useState } from "react";
import { Accordion, Button, Spinner } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogout } from "../../Feature/Login/LoginSlice";
import {
  setActiveKey,
  setActiveMenu,
  setNewActiveKey,
} from "../../Feature/Sidebar/ActiveKeySlice";
import { setLabelDate, setLabelMonth } from "../../Feature/Graph/label";
import PCMC from "../../Assets/PCMC-LOGO.png";

const Sidebar = () => {
  const userState = useSelector((state) => state.loginData.userData);
  const activeKeyRedux = useSelector(
    (state) => state.activeData.activeNumberKey
  );

  const [parentAccKey, setParentAcckey] = useState(null);
 
  const activeMenu = useSelector((state) => state.activeData?.activeMenu);

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);

  const dispatch = useDispatch();

  const handleLogout = () => {
    setLoader(true);
    dispatch(setLogout(null));
    dispatch(setActiveKey(0));
    dispatch(setLabelDate(""));
    dispatch(setLabelMonth(""));
    navigate("/");
    setLoader(false);
  };

  const handleAccordionChange = (activeKey, subActiveKey, tabName) => {
    
    dispatch(
      setActiveKey({
        activeKey: activeKey,
        subActiveKey: subActiveKey,
        activeTab: tabName,
      })
    );
  };

  const handleMenuChange = (activeKey, tabName, event) => {
    console.log("tabeName", tabName);
    // setParentAcckey((prev) => (prev === tabName ? null : tabName));
    dispatch(
      setActiveMenu({
        activeKey: activeKey,
        activeTab: tabName,
      })
    );
    if (activeKey === 0) {
      event.stopPropagation();
    }
  };

  return (
    <>
      <div className="sidenav-lg d-flex justify-content-between flex-column">
        <div>
          <div className="logoBg mb-4">
            <img src={PCMC} alt="PCMC Survey" width={70} />
          </div>

          <div className="menus">
            {userState?.usertype_keyname[0] === "admin" ||
            userState?.usertype_keyname[0] === "internalDev" ? (
              <>
                <Accordion flush defaultActiveKey={activeMenu?.activeKey}>
                  <Accordion.Item eventKey="2023-2024"
                    onClick={(event) => {
                      handleMenuChange(0, "2023-2024", event);
                    }}
                  >
                    <Accordion.Header className="newMenu">
                      2023-2024{" "}
                    </Accordion.Header>
                    <Accordion.Body style={{ padding: 0 }}>
                      <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>
                        <Accordion.Item
                          eventKey="0"
                          onClick={() => {
                            handleAccordionChange(0, 0, "Home");
                            navigate("/dashboard");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 0 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 0 ? (
                              <Icon.HouseDoorFill className="me-2" />
                            ) : (
                              <Icon.HouseDoor className="me-2 icon" />
                            )}
                            Home
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="1"
                          onClick={() => {
                            handleAccordionChange(1, 1, "Bill Distribution");
                            navigate("/dashboard/bill-distribution");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 1 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 1 ? (
                              <Icon.ReceiptCutoff className="me-2" />
                            ) : (
                              <Icon.ReceiptCutoff className="me-2 icon" />
                            )}
                            Bill Distribution
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="2"
                          onClick={() => {
                            handleAccordionChange(2, 2, "Manage Roles");
                            navigate("/dashboard/manage-roles");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 2 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 2 ? (
                              <Icon.PersonFillGear className="me-2" />
                            ) : (
                              <Icon.PersonGear className="me-2 icon" />
                            )}
                            Manage Users
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="3"
                          onClick={() => {
                            handleAccordionChange(3, 3, "Mahila Wise Bill Watap Report");
                            navigate("/dashboard/contractors");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 3 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 3 ? (
                              <Icon.PeopleFill className="me-2" />
                            ) : (
                              <Icon.People className="me-2 icon" />
                            )}
                            Mahila Wise Bill Watap Report
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="4"
                          onClick={() => {
                            handleAccordionChange(4, 4, "Distribution");
                            navigate("/dashboard/distribution");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 4 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 4 ? (
                              <Icon.BarChartFill className="me-2" />
                            ) : (
                              <Icon.BarChart className="me-2 icon" />
                            )}
                            Distribution Report
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="5"
                          onClick={() => {handleAccordionChange(5, 5, "Zone Wise Bill Analysis");
                            navigate("/dashboard/zonewise-bill-analysis");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 5 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 5 ? (
                              <Icon.ReceiptCutoff className="me-2" />
                            ) : (
                              <Icon.Receipt className="me-2 icon" />
                            )}
                            Zone Wise Bill Analysis
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey={6}
                          className={
                            activeKeyRedux.activeKey === 6
                              ? "activeMenu dropDown"
                              : "dropDown"
                          }
                        >
                          <Accordion.Header onClick={() => {
                            handleAccordionChange(6, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab);}}>
                            {activeKeyRedux?.activeKey === 6 ? (
                              <Icon.PeopleFill className="me-2" />
                            ) : (
                              <Icon.People className="me-2 icon" />
                            )}
                            Manage Telecalling
                          </Accordion.Header>

                          <Accordion.Body className="d-flex flex-column">
                            <Button
                              onClick={() => {
                                navigate("/dashboard/call-summary");
                                handleAccordionChange(6, 6.1, "Call Summary");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 6.1
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 6.1 ? (
                                <Icon.ChatTextFill className="me-2" />
                              ) : (
                                <Icon.ChatText className="me-2 icon" />
                              )}{" "}
                              Calling Summary
                            </Button>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/call-logs");
                                handleAccordionChange(6, 6.2, "Call Logs");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 6.2
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 6.2 ? (
                                <Icon.TelephoneForwardFill className="me-2" />
                              ) : (
                                <Icon.TelephoneForward className="me-2 icon" />
                              )}{" "}
                              Call Logs
                            </Button>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/dialed-call-logs");
                                handleAccordionChange(6, 6.3, "Dialed Call Logs");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 6.3
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 6.3 ? (
                                <Icon.TelephoneForwardFill className="me-2" />
                              ) : (
                                <Icon.TelephoneForward className="me-2 icon" />
                              )}
                              Dialed Call Logs
                            </Button>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/update-mobile");
                                handleAccordionChange(6, 6.4, "Update Mobile");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 6.4
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 6.4 ? (
                                <Icon.TelephoneFill className="me-2" />
                              ) : (
                                <Icon.Telephone className="me-2 icon" />
                              )}{" "}
                              Update Mobile
                            </Button>
                            <Button
                              onClick={() => {
                                handleAccordionChange(
                                  6,
                                  6.5,
                                  "Total Call Summary"
                                );
                                navigate("/dashboard/total-calling");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 6.5
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 6.5 ? (
                                <Icon.TelephonePlusFill className="me-2" />
                              ) : (
                                <Icon.TelephonePlus className="me-2 icon" />
                              )}{" "}
                              Total Call Summary
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="7"
                          className={
                            activeKeyRedux.activeKey === 7
                              ? "activeMenu dropDown"
                              : "dropDown"
                          }
                        >
                          <Accordion.Header
                            onClick={() => {
                              handleAccordionChange(
                                7,
                                activeKeyRedux.subActiveKey,
                                activeKeyRedux.activeTab
                              );
                            }}
                          >
                            {activeKeyRedux?.activeKey === 7 ? (
                              <Icon.Coin className="me-2" />
                            ) : (
                              <Icon.Coin className="me-2 icon" />
                            )}
                            Japti Details
                          </Accordion.Header>

                          <Accordion.Body className="d-flex flex-column">
                            <Button
                              onClick={() => {
                                navigate("/dashboard/japti-notice");
                                handleAccordionChange(7, 7.1, "Japti Notice");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 7.1
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 7.1 ? (
                                <Icon.CardHeading className="me-2" />
                              ) : (
                                <Icon.CardHeading className="me-2 icon" />
                              )}{" "}
                              Japti Notice
                            </Button>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/japti-karvai");
                                handleAccordionChange(7, 7.2, "Japti Karvai");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 7.2
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 7.2 ? (
                                <Icon.Coin className="me-2" />
                              ) : (
                                <Icon.Coin className="me-2 icon" />
                              )}{" "}
                              Japti Karvai
                            </Button>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/japti-mukti");
                                handleAccordionChange(7, 7.3, "Japti Mukti");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 7.3
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 7.3 ? (
                                <Icon.Coin className="me-2" />
                              ) : (
                                <Icon.Coin className="me-2 icon" />
                              )}{" "}
                              Japti Mukti
                            </Button>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/japti-karvai-zone-report");
                                handleAccordionChange(
                                  7,
                                  7.4,
                                  "Japti Karvai Zone Report"
                                );
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 7.4
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 7.4 ? (
                                <Icon.Coin className="me-2" />
                              ) : (
                                <Icon.Coin className="me-2 icon" />
                              )}
                              Karvai Zone Report
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey={8}
                          onClick={() => {
                            handleAccordionChange(8, 8, "SMS Sent Report");
                            navigate("/dashboard/sms-sent-report");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 8 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 8 ? (
                              <Icon.EnvelopeAtFill className="me-2" />
                            ) : (
                              <Icon.EnvelopeAt className="me-2 icon" />
                            )}
                            SMS Sent Report
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey={9}
                          onClick={() => {
                            handleAccordionChange(9, 9, "SMS Details");
                            navigate("/dashboard/sms-details");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 9 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 9 ? (
                              <Icon.EnvelopeAtFill className="me-2" />
                            ) : (
                              <Icon.EnvelopeAt className="me-2 icon" />
                            )}
                            SMS Details
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey={10}
                          onClick={() => {
                            handleAccordionChange(10, 10, "Analytics");
                            navigate("/dashboard/analytics");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 10 ? (
                              <Icon.BarChartFill className="me-2" />
                            ) : (
                              <Icon.BarChart className="me-2 icon" />
                            )}
                            Analytics
                          </Accordion.Header>
                        </Accordion.Item>

                        {userState?.usertype_keyname[0] === "internalDev" && (
                          <Accordion.Item
                            eventKey="7"
                            onClick={() => {
                              handleAccordionChange(
                                7,
                                7.3,
                                "Internal Developer"
                              );
                              navigate("/dashboard/internal-dev");
                            }}
                            className={
                              activeKeyRedux.activeKey === 7 ? "activeMenu" : ""
                            }
                          >
                            <Accordion.Header>
                              {activeKeyRedux.activeKey === 7 ? (
                                <Icon.DatabaseFillLock className="me-2" />
                              ) : (
                                <Icon.DatabaseLock className="me-2 icon" />
                              )}
                              Internal Dev
                            </Accordion.Header>
                          </Accordion.Item>
                        )}
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>

                <Accordion flush>
                  <Accordion.Item eventKey="2024-2025" 
                    onClick={(event) => {
                      handleMenuChange(1, "2024-2025", event);
                    }}
                  >
                    <Accordion.Header className="newMenu">
                      2024-2025
                    </Accordion.Header>

                    <Accordion.Body style={{ padding: 0 }}>
                      <Accordion
                        flush
                        defaultActiveKey={activeKeyRedux?.activeKey}
                      >
                        {/* New Dashboard Sidebar from here */}
                        <Accordion.Item
                          eventKey={11}
                          onClick={() => {
                            handleAccordionChange(11, 11, "Bill Distribution");
                            navigate("/dashboard/new-home");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 11 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 11 ? (
                              <Icon.HouseDoorFill className="me-2" />
                            ) : (
                              <Icon.HouseDoor className="me-2 icon" />
                            )}
                            Bill Distribution
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="12"
                          onClick={() => {
                            handleAccordionChange(12, 12, "Manage Users");
                            navigate("/dashboard/manage-roles");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 12 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 12 ? (
                              <Icon.PersonFillGear className="me-2" />
                            ) : (
                              <Icon.PersonGear className="me-2 icon" />
                            )}
                            Manage Users
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="13"
                          onClick={() => {
                            handleAccordionChange(13,13,"Property Visit Report");
                            navigate("/dashboard/new-visit-reports");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 13 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 13 ? (
                              <Icon.PeopleFill className="me-2" />
                            ) : (
                              <Icon.People className="me-2 icon" />
                            )}
                            Property Visit Report
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="14"
                          onClick={() => {
                            handleAccordionChange(
                              14,
                              14,
                              "Mahila Wise Bill Watap Report"
                            );
                            navigate("/dashboard/new-contractors");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 14 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 14 ? (
                              <Icon.PeopleFill className="me-2" />
                            ) : (
                              <Icon.People className="me-2 icon" />
                            )}
                            Mahila Wise Bill Watap Report
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="15"
                          onClick={() => {
                            handleAccordionChange(
                              15,
                              15,
                              "Zone Wise Bill Analysis"
                            );
                            navigate("/dashboard/new-bill-analysis");
                          }}
                          className={activeKeyRedux?.activeKey === 15 ? "activeMenu" : ""}
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 15 ? (
                              <Icon.Receipt className="me-2" />
                            ) : (
                              <Icon.Receipt className="me-2 icon" />
                            )}
                            Zone Wise Bill Analysis
                          </Accordion.Header>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="16"
                          onClick={() => {
                            handleAccordionChange(
                              16,
                              16,
                              "Gatwise Bill Analysis"
                            );
                            navigate("/dashboard/gatwise-bill-analysis");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 16 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 16 ? (
                              <Icon.Receipt className="me-2" />
                            ) : (
                              <Icon.Receipt className="me-2 icon" />
                            )}
                            Gatwise Bill Analysis
                          </Accordion.Header>
                        </Accordion.Item>

                        {/* <Accordion.Item
                          eventKey="16"
                          onClick={() => {
                            handleAccordionChange(16, 16, "Japti Notice");
                            navigate("/dashboard/new-japti-notice");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 16 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 16 ? (
                              <Icon.CardHeading className="me-2" />
                            ) : (
                              <Icon.CardHeading className="me-2 icon" />
                            )}
                            Japti Notice
                          </Accordion.Header>
                        </Accordion.Item> */}

                        {/* Telecalling */}
                        <Accordion.Item
                          eventKey={17}
                          className={
                            activeKeyRedux.activeKey === 17
                              ? "activeMenu dropDown"
                              : "dropDown"
                          }
                        >
                          <Accordion.Header
                            onClick={() => {
                              handleAccordionChange(
                                17,
                                activeKeyRedux.subActiveKey,
                                activeKeyRedux.activeTab,
                              );
                            }}
                          >
                            {activeKeyRedux?.activeKey === 17 ? (
                              <Icon.PeopleFill className="me-2" />
                            ) : (
                              <Icon.People className="me-2 icon" />
                            )}
                            Manage Telecalling
                          </Accordion.Header>

                          <Accordion.Body className="d-flex flex-column">
                            <Button
                              onClick={() => {
                                navigate("/dashboard/call-summary");
                                handleAccordionChange(17, 17.1, "Call Summary");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 17.1
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 17.1 ? (
                                <Icon.ChatTextFill className="me-2" />
                              ) : (
                                <Icon.ChatText className="me-2 icon" />
                              )}{" "}
                              Calling Summary
                            </Button>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/call-logs");
                                handleAccordionChange(17, 17.2, "Call Logs");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 17.2
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 17.2 ? (
                                <Icon.TelephoneForwardFill className="me-2" />
                              ) : (
                                <Icon.TelephoneForward className="me-2 icon" />
                              )}{" "}
                              Call Logs
                            </Button>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/dialed-call-logs");
                                handleAccordionChange(
                                  17,
                                  17.3,
                                  "Dialed Call Logs"
                                );
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 17.3
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 17.3 ? (
                                <Icon.TelephoneForwardFill className="me-2" />
                              ) : (
                                <Icon.TelephoneForward className="me-2 icon" />
                              )}
                              Dialed Call Logs
                            </Button>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/update-mobile");
                                handleAccordionChange(17, 17.4, "Update Mobile");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 17.4
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 17.4 ? (
                                <Icon.TelephoneFill className="me-2" />
                              ) : (
                                <Icon.Telephone className="me-2 icon" />
                              )}{" "}
                              Update Mobile
                            </Button>
                            <Button
                              onClick={() => {
                                handleAccordionChange(
                                  17,
                                  17.5,
                                  "Total Call Summary"
                                );
                                navigate("/dashboard/total-calling");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 17.5
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 17.5 ? (
                                <Icon.TelephonePlusFill className="me-2" />
                              ) : (
                                <Icon.TelephonePlus className="me-2 icon" />
                              )}{" "}
                              Total Call Summary
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>

                        {/* Japti */}
                        <Accordion.Item
                          eventKey="18"
                          className={
                            activeKeyRedux.activeKey === 18
                              ? "activeMenu dropDown"
                              : "dropDown"
                          }
                        >
                          <Accordion.Header
                            onClick={() => {
                              handleAccordionChange(
                                18,
                                activeKeyRedux.subActiveKey,
                                activeKeyRedux.activeTab
                              );
                            }}
                          >
                            {activeKeyRedux?.activeKey === 18 ? (
                              <Icon.Coin className="me-2" />
                            ) : (
                              <Icon.Coin className="me-2 icon" />
                            )}
                            Japti Details
                          </Accordion.Header>

                          <Accordion.Body className="d-flex flex-column">
                            <Button
                              onClick={() => {
                                navigate("/dashboard/new-japti-notice");
                                handleAccordionChange(18, 18.1, "Japti Notice");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 18.1
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 18.1 ? (
                                <Icon.CardHeading className="me-2" />
                              ) : (
                                <Icon.CardHeading className="me-2 icon" />
                              )}{" "}
                              Japti Notice
                            </Button>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/japti-karvai");
                                handleAccordionChange(18, 18.2, "Japti Karvai");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 18.2
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 18.2 ? (
                                <Icon.Coin className="me-2" />
                              ) : (
                                <Icon.Coin className="me-2 icon" />
                              )}{" "}
                              Japti Karvai
                            </Button>
                            <Button
                              onClick={() => {
                                navigate("/dashboard/japti-mukti");
                                handleAccordionChange(18, 18.3, "Japti Mukti");
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 18.3
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 18.3 ? (
                                <Icon.Coin className="me-2" />
                              ) : (
                                <Icon.Coin className="me-2 icon" />
                              )}{" "}
                              Japti Mukti
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey={19}
                          className={
                            activeKeyRedux.activeKey === 19
                              ? "activeMenu dropDown"
                              : "dropDown"
                          }
                        >
                          <Accordion.Header
                            onClick={() => {
                              handleAccordionChange(
                                19,
                                activeKeyRedux.subActiveKey,
                                activeKeyRedux.activeTab
                              );
                            }}
                          >
                            {activeKeyRedux?.activeKey === 19 ? (
                              <Icon.Receipt className="me-2" />
                            ) : (
                              <Icon.Receipt className="me-2 icon" />
                            )}
                            Manage Bills
                          </Accordion.Header>

                          <Accordion.Body className="d-flex flex-column">
                            <Button
                              onClick={() => {
                                navigate("/dashboard/deleted-bill-history");
                                handleAccordionChange(
                                  19,
                                  19.2,
                                  "Deleted Bill History"
                                );
                              }}
                              className={
                                activeKeyRedux?.subActiveKey === 19.2
                                  ? "accordionNavActive mb-3"
                                  : "accordionNav mb-3"
                              }
                            >
                              {activeKeyRedux?.subActiveKey === 19.2 ? (
                                <Icon.ReceiptCutoff className="me-2" />
                              ) : (
                                <Icon.ReceiptCutoff className="me-2 icon" />
                              )}{" "}
                              Deleted Bill History
                            </Button>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="20"
                          onClick={() => {
                            handleAccordionChange(20, 20, "Rejected Bills");
                            navigate("/dashboard/rejected-list");
                          }}
                          className={
                            activeKeyRedux?.activeKey === 20 ? "activeMenu" : ""
                          }
                        >
                          <Accordion.Header>
                            {activeKeyRedux?.activeKey === 20 ? (
                              <Icon.FileEarmarkExcelFill className="me-2" />
                            ) : (
                              <Icon.FileEarmarkExcel className="me-2 icon" />
                            )}
                            Rejected Bills
                          </Accordion.Header>
                        </Accordion.Item>

                        {userState?.usertype_keyname[0] === "internalDev" && (
                          <Accordion.Item
                            eventKey="21"
                            onClick={() => {
                              handleAccordionChange(
                                21,
                                21,
                                "Internal Developer"
                              );
                              navigate("/dashboard/internal-dev-New");
                            }}
                            className={
                              activeKeyRedux.activeKey === 21 ? "activeMenu" : ""
                            }
                          >
                            <Accordion.Header>
                              {activeKeyRedux.activeKey === 21 ? (
                                <Icon.DatabaseFillLock className="me-2" />
                              ) : (
                                <Icon.DatabaseLock className="me-2 icon" />
                              )}
                              Internal Dev
                            </Accordion.Header>
                          </Accordion.Item>
                        )}
                      </Accordion>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </>
            ) : (
              ""
            )}

            {userState.usertype_keyname[0] === "supervisor" ? (
              <Accordion flush>
                <Accordion.Item
                  eventKey={0}
                  onClick={() => {
                    handleAccordionChange(0, 0, "Home");
                    navigate("/dashboard");
                  }}
                  className={activeKeyRedux.activeKey === 0 ? "activeMenu" : ""}
                >
                  <Accordion.Header>
                    {activeKeyRedux.activeKey === 0 ? (
                      <Icon.HouseDoorFill className="me-2" />
                    ) : (
                      <Icon.HouseDoor className="me-2 icon" />
                    )}
                    Home
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item
                  eventKey={1}
                  onClick={() => {
                    handleAccordionChange(1, 1, "Manage Roles");
                    navigate("/dashboard/manage-roles");
                  }}
                  className={activeKeyRedux.activeKey === 1 ? "activeMenu" : ""}
                >
                  <Accordion.Header>
                    {activeKeyRedux.activeKey === 1 ? (
                      <Icon.PersonFillGear className="me-2" />
                    ) : (
                      <Icon.PersonGear className="me-2 icon" />
                    )}
                    Manage Users
                  </Accordion.Header>
                </Accordion.Item>
              </Accordion>
            ) : (
              ""
            )}

            {userState.usertype_keyname[0] === "zonalofficer" ? (
              // <Accordion flush>
              //   <Accordion.Item
              //     eventKey={0}
              //     onClick={() => {
              //       handleAccordionChange(0, 0, "Home");
              //       navigate("/dashboard");
              //     }}
              //     className={activeKeyRedux.activeKey === 0 ? "activeMenu" : ""}
              //   >
              //     <Accordion.Header>
              //       {activeKeyRedux.activeKey === 0 ? (
              //         <Icon.HouseDoorFill className="me-2" />
              //       ) : (
              //         <Icon.HouseDoor className="me-2 icon" />
              //       )}
              //       Home
              //     </Accordion.Header>
              //   </Accordion.Item>

              //   <Accordion.Item
              //     eventKey={1}
              //     className={
              //       activeKeyRedux.activeKey === 1
              //         ? "activeMenu dropDown"
              //         : "dropDown"
              //     }
              //   >
              //     <Accordion.Header
              //       onClick={() => {
              //         handleAccordionChange(
              //           1,
              //           activeKeyRedux.subActiveKey,
              //           activeKeyRedux.activeTab
              //         );
              //       }}
              //     >
              //       {activeKeyRedux?.activeKey === 1 ? (
              //         <Icon.PeopleFill className="me-2" />
              //       ) : (
              //         <Icon.People className="me-2 icon" />
              //       )}
              //       Manage Telecalling
              //     </Accordion.Header>

              //     <Accordion.Body className="d-flex flex-column">
              //       <Button
              //         onClick={() => {
              //           navigate("/dashboard/zonal-call-logs");
              //           handleAccordionChange(1, 1.2, "Call Logs");
              //         }}
              //         className={
              //           activeKeyRedux?.subActiveKey === 1.2
              //             ? "accordionNavActive mb-3"
              //             : "accordionNav mb-3"
              //         }
              //       >
              //         {activeKeyRedux?.subActiveKey === 1.2 ? (
              //           <Icon.TelephoneForwardFill className="me-2" />
              //         ) : (
              //           <Icon.TelephoneForward className="me-2 icon" />
              //         )}{" "}
              //         Call Logs
              //       </Button>
              //       <Button
              //         onClick={() => {
              //           navigate("/dashboard/zonal-dialed-call-logs");
              //           handleAccordionChange(1, 1.3, "Dialed Call Logs");
              //         }}
              //         className={
              //           activeKeyRedux?.subActiveKey === 1.3
              //             ? "accordionNavActive mb-3"
              //             : "accordionNav mb-3"
              //         }
              //       >
              //         {activeKeyRedux?.subActiveKey === 1.3 ? (
              //           <Icon.TelephoneForwardFill className="me-2" />
              //         ) : (
              //           <Icon.TelephoneForward className="me-2 icon" />
              //         )}
              //         Dialed Call Logs
              //       </Button>
              //       {/* <Button onClick={() => { navigate("/dashboard/update-mobile"); handleAccordionChange(1, 1.4, "Update Mobile") }} className={activeKeyRedux?.subActiveKey === 1.4 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
              //                                                           {activeKeyRedux?.subActiveKey === 1.4 ? <Icon.TelephoneFill className='me-2' /> : <Icon.Telephone className='me-2 icon' />} Update Mobile
              //                                                     </Button> */}
              //       <Button
              //         onClick={() => {
              //           handleAccordionChange(1, 1.4, "Total Call Summary");
              //           navigate("/dashboard/zonal-total-calling");
              //         }}
              //         className={
              //           activeKeyRedux?.subActiveKey === 1.4
              //             ? "accordionNavActive mb-3"
              //             : "accordionNav mb-3"
              //         }
              //       >
              //         {activeKeyRedux?.subActiveKey === 1.4 ? (
              //           <Icon.TelephonePlusFill className="me-2" />
              //         ) : (
              //           <Icon.TelephonePlus className="me-2 icon" />
              //         )}{" "}
              //         Total Call Summary
              //       </Button>
              //     </Accordion.Body>
              //   </Accordion.Item>

              //   <Accordion.Item
              //     eventKey="2"
              //     className={
              //       activeKeyRedux.activeKey === 2
              //         ? "activeMenu dropDown"
              //         : "dropDown"
              //     }
              //   >
              //     <Accordion.Header
              //       onClick={() => {
              //         handleAccordionChange(
              //           2,
              //           activeKeyRedux.subActiveKey,
              //           activeKeyRedux.activeTab
              //         );
              //       }}
              //     >
              //       {activeKeyRedux?.activeKey === 2 ? (
              //         <Icon.Coin className="me-2" />
              //       ) : (
              //         <Icon.Coin className="me-2 icon" />
              //       )}
              //       Japti Details
              //     </Accordion.Header>

              //     <Accordion.Body className="d-flex flex-column">
              //       <Button
              //         onClick={() => {
              //           navigate("/dashboard/zonal-japti-notice");
              //           handleAccordionChange(2, 2.1, "Japti Notice");
              //         }}
              //         className={
              //           activeKeyRedux?.subActiveKey === 2.1
              //             ? "accordionNavActive mb-3"
              //             : "accordionNav mb-3"
              //         }
              //       >
              //         {activeKeyRedux?.subActiveKey === 2.1 ? (
              //           <Icon.CardHeading className="me-2" />
              //         ) : (
              //           <Icon.CardHeading className="me-2 icon" />
              //         )}{" "}
              //         Japti Notice
              //       </Button>
              //       <Button
              //         onClick={() => {
              //           navigate("/dashboard/zonal-japti-karvai");
              //           handleAccordionChange(2, 2.2, "Japti Karvai");
              //         }}
              //         className={
              //           activeKeyRedux?.subActiveKey === 2.2
              //             ? "accordionNavActive mb-3"
              //             : "accordionNav mb-3"
              //         }
              //       >
              //         {activeKeyRedux?.subActiveKey === 2.2 ? (
              //           <Icon.Coin className="me-2" />
              //         ) : (
              //           <Icon.Coin className="me-2 icon" />
              //         )}{" "}
              //         Japti Karvai
              //       </Button>
              //       <Button
              //         onClick={() => {
              //           navigate("/dashboard/zonal-japti-mukti");
              //           handleAccordionChange(2, 2.3, "Japti Mukti");
              //         }}
              //         className={
              //           activeKeyRedux?.subActiveKey === 2.3
              //             ? "accordionNavActive mb-3"
              //             : "accordionNav mb-3"
              //         }
              //       >
              //         {activeKeyRedux?.subActiveKey === 2.3 ? (
              //           <Icon.Coin className="me-2" />
              //         ) : (
              //           <Icon.Coin className="me-2 icon" />
              //         )}{" "}
              //         Japti Mukti
              //       </Button>
              //     </Accordion.Body>
              //   </Accordion.Item>

              //   {/* <Accordion.Item eventKey={1} onClick={() => { handleAccordionChange(1, 1, "Zonal Target"); navigate("/dashboard/zonal-target"); }} className={activeKeyRedux.activeKey === 1 ? "activeMenu" : ""}>
              //                                   <Accordion.Header >{activeKeyRedux.activeKey === 1 ? <Icon.Calendar2CheckFill className='me-2' /> : <Icon.Calendar2Check className='me-2 icon' />}Target Report</Accordion.Header>
              //                             </Accordion.Item>

              //                             <Accordion.Item eventKey={2} onClick={() => { handleAccordionChange(2, 2, "Zonal Contractors"); navigate("/dashboard/zonal-contractors"); }} className={activeKeyRedux.activeKey === 2 ? "activeMenu" : ""}>
              //                                   <Accordion.Header >{activeKeyRedux.activeKey === 2 ? <Icon.PeopleFill className='me-2' /> : <Icon.People className='me-2 icon' />}Mahila Wise Bill Watap Report</Accordion.Header>
              //                             </Accordion.Item>

              //                               <Accordion.Item eventKey={3} onClick={() => { handleAccordionChange(3, 3, "Zonal Distributions"); navigate("/dashboard/zonal-distribution"); }} className={activeKeyRedux.activeKey === 3 ? "activeMenu" : ""}>
              //                                   <Accordion.Header >{activeKeyRedux.activeKey === 3 ? <Icon.CashStack className='me-2' /> : <Icon.Cash className='me-2 icon' />}Distribution Report</Accordion.Header>
              //                             </Accordion.Item>

              //                             <Accordion.Item eventKey={4} onClick={() => { handleAccordionChange(4, 4, "Zonal Distribution History"); navigate("/dashboard/zonal-distribution-history"); }} className={activeKeyRedux.activeKey === 4 ? "activeMenu" : ""}>
              //                                   <Accordion.Header >{activeKeyRedux.activeKey === 4 ? <Icon.ReceiptCutoff className='me-2' /> : <Icon.Receipt className='me-2 icon' />}Distribution History</Accordion.Header>
              //                             </Accordion.Item> */}

              //   {/* <Accordion.Item eventKey="6" className={activeKeyRedux.activeKey === 6 ? "activeMenu dropDown" : "dropDown"}>
              //                                <Accordion.Header onClick={() => { handleAccordionChange(6, activeKeyRedux.subActiveKey, activeKeyRedux.activeTab); }}>
              //                                   {activeKeyRedux?.activeKey === 6 ? <Icon.Coin className='me-2' /> : <Icon.Coin className='me-2 icon' />}Japti Details
              //                               </Accordion.Header>

              //                               <Accordion.Body className="d-flex flex-column">
              //                                   <Button onClick={() => { navigate("/dashboard/zonal-japti-notice"); handleAccordionChange(6, 6.1, "Japti Notice") }} className={activeKeyRedux?.subActiveKey === 6.1 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
              //                                    {activeKeyRedux?.subActiveKey === 6.1 ? <Icon.CardHeading className='me-2' /> : <Icon.CardHeading className='me-2 icon' />} Japti Notice
              //                                  </Button>
              //                                 <Button onClick={() => { navigate("/dashboard/japti-karvai"); handleAccordionChange(6, 6.2, "Japti Karvai") }} className={activeKeyRedux?.subActiveKey === 6.2 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
              //                                   {activeKeyRedux?.subActiveKey === 6.2 ? <Icon.Coin className='me-2' /> : <Icon.Coin className='me-2 icon' />} Japti Karvai
              //                                 </Button>
              //                                 <Button onClick={() => { navigate("/dashboard/japti-mukti"); handleAccordionChange(6, 6.3, "Japti Mukti"); }} className={activeKeyRedux?.subActiveKey === 6.3 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
              //                                   {activeKeyRedux?.subActiveKey === 6.3 ? <Icon.Coin className='me-2' /> : <Icon.Coin className='me-2 icon' />} Japti Mukti
              //                                 </Button>
              //                                 <Button onClick={() => { navigate("/dashboard/japti-karvai-zone-report"); handleAccordionChange(6, 6.4, "Japti Karvai Zone Report"); }} className={activeKeyRedux?.subActiveKey === 6.4 ? "accordionNavActive mb-3" : "accordionNav mb-3"}>
              //                                   {activeKeyRedux?.subActiveKey === 6.4 ? <Icon.Coin className='me-2' /> : <Icon.Coin className='me-2 icon' />}Karvai Zone Report
              //                                 </Button>
              //                              </Accordion.Body>
              //                       </Accordion.Item> */}
              // </Accordion>
              <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>
                {/* New Dashboard Sidebar from here */}
                <Accordion.Item
                  eventKey={10}
                  onClick={() => {
                    handleAccordionChange(10, 10, "Bill Distribution");
                    navigate("/dashboard/new-home");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 10 ? (
                      <Icon.HouseDoorFill className="me-2" />
                    ) : (
                      <Icon.HouseDoor className="me-2 icon" />
                    )}
                    Bill Distribution
                  </Accordion.Header>
                </Accordion.Item>

                {/* <Accordion.Item
                eventKey="2"
                onClick={() => {
                  handleAccordionChange(14, 14, "Manage Users");
                  navigate("/dashboard/manage-roles");
                }}
                className={
                  activeKeyRedux?.activeKey === 14 ? "activeMenu" : ""
                }
              >
                <Accordion.Header>
                  {activeKeyRedux?.activeKey === 14 ? (
                    <Icon.PersonFillGear className="me-2" />
                  ) : (
                    <Icon.PersonGear className="me-2 icon" />
                  )}
                  Manage Users
                </Accordion.Header>
              </Accordion.Item> */}

                <Accordion.Item
                  eventKey="11"
                  onClick={() => {
                    handleAccordionChange(11, 11, "Property Visit Report");
                    navigate("/dashboard/new-visit-reports");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 11 ? "activeMenu" : ""
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 11 ? (
                      <Icon.PeopleFill className="me-2" />
                    ) : (
                      <Icon.People className="me-2 icon" />
                    )}
                    Property Visit Report
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item
                  eventKey="12"
                  onClick={() => {
                    handleAccordionChange(
                      12,
                      12,
                      "Mahila Wise Bill Watap Report"
                    );
                    navigate("/dashboard/new-contractors");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 12 ? "activeMenu" : ""
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 12 ? (
                      <Icon.PeopleFill className="me-2" />
                    ) : (
                      <Icon.People className="me-2 icon" />
                    )}
                    Mahila Wise Bill Watap Report
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item
                  eventKey="13"
                  onClick={() => {
                    handleAccordionChange(13, 13, "Zone Wise Bill Analysis");
                    navigate("/dashboard/new-bill-analysis");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 13 ? "activeMenu" : ""
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 13 ? (
                      <Icon.Receipt className="me-2" />
                    ) : (
                      <Icon.Receipt className="me-2 icon" />
                    )}
                    Zone Wise Bill Analysis
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item
                  eventKey="18"
                  onClick={() => {
                    handleAccordionChange(18, 18, "Gatwise Bill Analysis");
                    navigate("/dashboard/gatwise-bill-analysis");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 18 ? "activeMenu" : ""
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 18 ? (
                      <Icon.Receipt className="me-2" />
                    ) : (
                      <Icon.Receipt className="me-2 icon" />
                    )}
                    Gatwise Bill Analysis
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item
                  eventKey="16"
                  onClick={() => {
                    handleAccordionChange(16, 16, "Japti Notice");
                    navigate("/dashboard/new-japti-notice");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 16 ? "activeMenu" : ""
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 16 ? (
                      <Icon.CardHeading className="me-2" />
                    ) : (
                      <Icon.CardHeading className="me-2 icon" />
                    )}
                    Japti Notice
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item
                  eventKey={15}
                  className={
                    activeKeyRedux.activeKey === 15
                      ? "activeMenu dropDown"
                      : "dropDown"
                  }
                >
                  <Accordion.Header
                    onClick={() => {
                      handleAccordionChange(
                        15,
                        activeKeyRedux.subActiveKey,
                        activeKeyRedux.activeTab
                      );
                    }}
                  >
                    {activeKeyRedux?.activeKey === 15 ? (
                      <Icon.Receipt className="me-2" />
                    ) : (
                      <Icon.Receipt className="me-2 icon" />
                    )}
                    Manage Bills
                  </Accordion.Header>

                  <Accordion.Body className="d-flex flex-column">
                    {/* <Button
                      onClick={() => {
                        navigate("/dashboard/bill-report");
                        handleAccordionChange(15, 15.1, "Bill Report");
                      }}
                      className={
                        activeKeyRedux?.subActiveKey === 15.1
                          ? "accordionNavActive mb-3"
                          : "accordionNav mb-3"
                      }
                    >
                      {activeKeyRedux?.subActiveKey === 15.1 ? (
                        <Icon.ReceiptCutoff className="me-2" />
                      ) : (
                        <Icon.ReceiptCutoff className="me-2 icon" />
                      )}{" "}
                      Bill Report
                    </Button> */}

                    <Button
                      onClick={() => {
                        navigate("/dashboard/deleted-bill-history");
                        handleAccordionChange(15, 15.2, "Deleted Bill History");
                      }}
                      className={
                        activeKeyRedux?.subActiveKey === 15.2
                          ? "accordionNavActive mb-3"
                          : "accordionNav mb-3"
                      }
                    >
                      {activeKeyRedux?.subActiveKey === 15.2 ? (
                        <Icon.ReceiptCutoff className="me-2" />
                      ) : (
                        <Icon.ReceiptCutoff className="me-2 icon" />
                      )}{" "}
                      Deleted Bill History
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ) : (
              ""
            )}

            {userState.usertype_keyname[0] === "mavim" ? (
              <Accordion flush defaultActiveKey={activeKeyRedux?.activeKey}>
                {/* New Dashboard Sidebar from here */}
                <Accordion.Item
                  eventKey={10}
                  onClick={() => {
                    handleAccordionChange(10, 10, "Bill Distribution");
                    navigate("/dashboard/new-home");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 10 ? "activeMenu" : ""
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 10 ? (
                      <Icon.HouseDoorFill className="me-2" />
                    ) : (
                      <Icon.HouseDoor className="me-2 icon" />
                    )}
                    Bill Distribution
                  </Accordion.Header>
                </Accordion.Item>

                {/* <Accordion.Item
                        eventKey="2"
                        onClick={() => {
                          handleAccordionChange(14, 14, "Manage Users");
                          navigate("/dashboard/manage-roles");
                        }}
                        className={
                          activeKeyRedux?.activeKey === 14 ? "activeMenu" : ""
                        }
                      >
                        <Accordion.Header>
                          {activeKeyRedux?.activeKey === 14 ? (
                            <Icon.PersonFillGear className="me-2" />
                          ) : (
                            <Icon.PersonGear className="me-2 icon" />
                          )}
                          Manage Users
                        </Accordion.Header>
                      </Accordion.Item> */}

                <Accordion.Item
                  eventKey="11"
                  onClick={() => {
                    handleAccordionChange(11, 11, "Property Visit Report");
                    navigate("/dashboard/new-visit-reports");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 11 ? "activeMenu" : ""
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 11 ? (
                      <Icon.PeopleFill className="me-2" />
                    ) : (
                      <Icon.People className="me-2 icon" />
                    )}
                    Property Visit Report
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item
                  eventKey="12"
                  onClick={() => {
                    handleAccordionChange(
                      12,
                      12,
                      "Mahila Wise Bill Watap Report"
                    );
                    navigate("/dashboard/new-contractors");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 12 ? "activeMenu" : ""
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 12 ? (
                      <Icon.PeopleFill className="me-2" />
                    ) : (
                      <Icon.People className="me-2 icon" />
                    )}
                    Mahila Wise Bill Watap Report
                  </Accordion.Header>
                </Accordion.Item>

                <Accordion.Item
                  eventKey={15}
                  className={
                    activeKeyRedux.activeKey === 15
                      ? "activeMenu dropDown"
                      : "dropDown"
                  }
                >
                  <Accordion.Header
                    onClick={() => {
                      handleAccordionChange(
                        15,
                        activeKeyRedux.subActiveKey,
                        activeKeyRedux.activeTab
                      );
                    }}
                  >
                    {activeKeyRedux?.activeKey === 15 ? (
                      <Icon.Receipt className="me-2" />
                    ) : (
                      <Icon.Receipt className="me-2 icon" />
                    )}
                    Manage Bills
                  </Accordion.Header>

                  <Accordion.Body className="d-flex flex-column">
                    <Button
                      onClick={() => {
                        navigate("/dashboard/bill-report");
                        handleAccordionChange(15, 15.1, "Bill Report");
                      }}
                      className={
                        activeKeyRedux?.subActiveKey === 15.1
                          ? "accordionNavActive mb-3"
                          : "accordionNav mb-3"
                      }
                    >
                      {activeKeyRedux?.subActiveKey === 15.1 ? (
                        <Icon.ReceiptCutoff className="me-2" />
                      ) : (
                        <Icon.ReceiptCutoff className="me-2 icon" />
                      )}{" "}
                      Bill Report
                    </Button>

                    <Button
                      onClick={() => {
                        navigate("/dashboard/deleted-bill-history");
                        handleAccordionChange(15, 15.2, "Deleted Bill History");
                      }}
                      className={
                        activeKeyRedux?.subActiveKey === 15.2
                          ? "accordionNavActive mb-3"
                          : "accordionNav mb-3"
                      }
                    >
                      {activeKeyRedux?.subActiveKey === 15.2 ? (
                        <Icon.ReceiptCutoff className="me-2" />
                      ) : (
                        <Icon.ReceiptCutoff className="me-2 icon" />
                      )}{" "}
                      Deleted Bill History
                    </Button>
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item
                  eventKey="17"
                  onClick={() => {
                    handleAccordionChange(17, 17, "Rejected Bills");
                    navigate("/dashboard/rejected-list");
                  }}
                  className={
                    activeKeyRedux?.activeKey === 17 ? "activeMenu" : ""
                  }
                >
                  <Accordion.Header>
                    {activeKeyRedux?.activeKey === 17 ? (
                      <Icon.FileEarmarkExcelFill className="me-2" />
                    ) : (
                      <Icon.FileEarmarkExcel className="me-2 icon" />
                    )}
                    Rejected Bills
                  </Accordion.Header>
                </Accordion.Item>
              </Accordion>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="pb-4 ps-2">
          <Button className="btn-logout w-100" onClick={handleLogout}>
            {loader ? (
              <Spinner animation="border" variant="light" className="spinner" />
            ) : (
              <>
                <Icon.BoxArrowLeft size={20} className="me-2" />
                Logout
              </>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

{
  /* <Accordion.Item eventKey="3" onClick={ () => {setActivekey(3); navigate("/admin/contractors"); }} className={activeKey===3 ? "activeMenu": ""}>
           <Accordion.Header className='dropDown'>{activeKey===3 ? <Icon.PlusCircleFill className='me-2'/>: <Icon.PlusCircle className='me-2 icon'/>}Other</Accordion.Header>
        <Accordion.Body>
          <Nav variant="pills" defaultActiveKey="/menu Items" className="d-flex flex-column">
            <Nav.Item>
                <Nav.Link onClick={ () => {navigate("/admin")}}  eventKey="/admin">
                  <Icon.Activity className="me-3"/>Menu 1</Nav.Link>
            </Nav.Item>
            <Nav.Item>
                <Nav.Link onClick={ () => {navigate("/")}} eventKey="/">
                   <div><Icon.Calendar2Week className="me-3"/></div><div>Menu 2</div></Nav.Link>
            </Nav.Item>
           </Nav>
     </Accordion.Body>
     </Accordion.Item> */
}
