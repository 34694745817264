import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, Form, Button, Spinner, Modal } from 'react-bootstrap';
import * as Icon from 'react-bootstrap-icons';
import moment from 'moment';
import * as XLSX from 'xlsx';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAsync } from 'react-select/async';

const InternalDevNew = () => {

  const [loader, setLoader] = useState(false);
  const [loadingButton, setLoadingButton] = useState("");
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const [startDateErr, setStartDateErr] = useState(false);
  const [endDateErr, setEndDateErr] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [showAllModal, setShowAllModal] = useState(false)

  // useEffect(()=>{
  //     getCallLogReport();
  // },[])

  const validate = () => {
    let isValid = true;
    if (!startDate) {
      isValid = false;
      setStartDateErr(true);
    }
    else if (!endDate) {
      isValid = false;
      setEndDateErr(true);
    }
    return isValid;
  }

  // for export date wise data
  const getCallLogReport = async (callType) => {
    if (validate()) {
      // setLoader(true);
      // /tc/getAllCallings
      setLoadingButton(callType);
      await fetch(process.env.REACT_APP_BASE_URL + '/tc/teleCallingExport', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            callType : callType, // ["Call", "Dial Call", "Both"]
            fromDate : startDate,
            toDate : endDate,
            callingDate : "",
            financialYear : "2024-2025"
        })
      }).then((res) => res.json()).then((response) => {
        
        if (response?.message === "No data found") {
          toast.error("No Data Found");
          // setLoader(false)
          setLoadingButton("")
          setShowModal(false)
        }else{
          if(callType === "Call"){
            const keys_to_keep = [
              "propertyCode",
              "propertyName",
              "propertyAddress",
              "zonename",
              "gatname",
              "callingPerson_ContactNo",
              "callingPerson_Name",
              "callingDate",
              "UniqueId",
              "callingNumber",
              "callingStatus",
              "callingResponse",
              "customer_time",
              "recording_file",
              "talk_duration",
              "isSMSSent",
              "isHistory",
              "isMobileCall",
              "isCallMade",
            ];
            const ExcelData = response?.calling?.map((e) => {
              const obj = {};
              keys_to_keep.forEach((k) => (obj[k] = e[k]));
              return obj;
            });
            downloadExcel(ExcelData, `${callType}`+` Report ${moment(startDate).format("DD-MM-YYYY") + ' To ' + moment(endDate).format("DD-MM-YYYY")}.xlsx`);
            setLoadingButton("")
          }else{
            const keys_to_keep = [
              "callingPerson_ContactNo",
              "callingPerson_Name",
              "callingDate",
              "callingStatus",
              "callingNumber",
              "customer_time",
              "talk_duration",
              "recording_file",
              "UniqueId",
              "callingResponse",
              "isSMSSent",
              "isHistory",
              "isCallMade",
            ];
            const ExcelData = response?.dialCalling?.map((e) => {
              const obj = {};
              keys_to_keep.forEach((k) => (obj[k] = e[k]));
              return obj;
            });
            downloadExcel(ExcelData, `${callType}`+` Report ${moment(startDate).format("DD-MM-YYYY") + ' To ' + moment(endDate).format("DD-MM-YYYY")}.xlsx`);
            setLoadingButton("")
          }
        }
      }).catch((err) => {
        console.log("Error while getting Excel Call Report", err);
        // setLoader(false);
        setLoadingButton("")
      })
    }
    else {
      setLoader(false);
      setLoadingButton("")
    }
  }

  // for export all data
  const getCallLogReportAllData = async (callType) => {
    setStartDate('');
    setEndDate('');
    setLoadingButton(callType)
    // setLoader(true);
    // /tc/getAllCallings
    await fetch(process.env.REACT_APP_BASE_URL + '/tc/teleCallingExport', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        callType : callType, // ["Call", "Dial Call", "Both"]
        fromDate : '',
        toDate : '',
        callingDate : "",
        financialYear : "2024-2025"
      }),
    })
    .then((res) => res.json())
    .then((response) => {
      console.log("response",response);
      
      if (response.message === "No data found") {
          toast.error("No Data Found");
          // setLoader(false)
          setLoadingButton("")
          setShowAllModal(false)
      }
      else {
          if(callType === "Call"){
            const keys_to_keep = [
              "propertyCode",
              "propertyName",
              "propertyAddress",
              "zonename",
              "gatname",
              "callingPerson_ContactNo",
              "callingPerson_Name",
              "callingDate",
              "UniqueId",
              "callingNumber",
              "callingStatus",
              "callingResponse",
              "customer_time",
              "recording_file",
              "talk_duration",
              "isSMSSent",
              "isHistory",
              "isMobileCall",
              "isCallMade",
            ];
            const ExcelData = response?.calling?.map((e) => {
              const obj = {};
              keys_to_keep.forEach((k) => (obj[k] = e[k]));
              return obj;
            });
            downloadExcel(ExcelData, `${callType}`+` Logs Report.xlsx`);
            setLoadingButton("")
          }else{
            const keys_to_keep = [
              "callingPerson_ContactNo",
              "callingPerson_Name",
              "callingDate",
              "callingStatus",
              "callingNumber",
              "customer_time",
              "talk_duration",
              "recording_file",
              "UniqueId",
              "callingResponse",
              "isSMSSent",
              "isHistory",
              "isCallMade",
            ];
            const ExcelData = response?.dialCalling?.map((e) => {
              const obj = {};
              keys_to_keep.forEach((k) => (obj[k] = e[k]));
              return obj;
            });
            downloadExcel(ExcelData, `${callType}`+` Logs Report.xlsx`);
            setLoadingButton("")
          }
      }
      })
      .catch((err) => {
        console.log('Error while getting Excel Call Report', err);
        // setLoader(false);
        setLoadingButton("")
      });
  }

  const downloadExcel = (heading, fileName) => {
    let dataSet = [];
    dataSet = heading;
    const worksheet = XLSX.utils.json_to_sheet(dataSet);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, fileName);
  };

  return (
    <div className='InternalDev'>
      <ToastContainer />
      <Container>
        <div className='d-flex justify-content-center align-items-center'>
          <Icon.DatabaseLock className='me-2' size={22} color='#073c7c' />
          <h3 className='mb-0'>Internal Dev</h3>
        </div>

        <Row className='justify-content-center my-4'>
          <Col md={5}>
            <Card>
                <Card.Header className='py-3'>
                  <h5 className='text-center mb-0'>
                  <Icon.PersonVcard className='me-2' size={18} />Export Call and Dialed Call Log Report</h5>
                </Card.Header>

                <Card.Body className='py-4'>
                  <Row className='justify-content-between'>
                    <Col md={5}>
                      <Form.Group className='mb-3 mb-md-0'>
                        <Form.Label>Start Date</Form.Label>
                        <Form.Control type="date" value={startDate} onChange={(e) => {
                          setStartDate(moment(e.target.value).format("YYYY-MM-DD"));
                          e.target.value.length > 0 ? setStartDateErr(false) : setStartDateErr(true)
                        }} />
                        {startDateErr ? <p className='errMsg'>Please select start date</p> : ''}
                      </Form.Group>
                    </Col>

                    <Col md={5}>
                      <Form.Group className='mb-3 mb-md-0'>
                        <Form.Label>End Date</Form.Label>
                        <Form.Control type="date" disabled={startDate ? false : true} min={startDate} value={endDate}
                          max={new Date().toISOString().split("T")[0]}
                          onChange={(e) => { setEndDate(moment(e.target.value).format("YYYY-MM-DD")); e.target.value.length > 0 ? setEndDateErr(false) : setEndDateErr(true) }} />
                        {endDateErr ? <p className='errMsg'>Please select end date</p> : ''}
                      </Form.Group>
                    </Col>

                    <div>
                      <Button className="btn-submit mt-3 mx-auto" 
                        // onClick={getCallLogReport} 
                        onClick={() =>{
                          if(validate()){
                            setShowModal(true)
                          } 
                        }}>
                        <Icon.ArrowDownCircle className='me-2' />
                        Export Excel
                      </Button>
                    </div>
                  </Row>
                </Card.Body>
              
                  <Card.Footer className='py-3'>
                    <Button className="btn-submit  mx-auto" 
                      // onClick={getCallLogReportAllData}//
                      onClick={() =>setShowAllModal(true)} 
                     >
                        <Icon.ArrowDownCircle className='me-2' />
                        All Data Export Excel
                    </Button>
                  </Card.Footer>

            </Card>
          </Col>
        </Row>

        {/* Modal with date filter */}
        <Modal
          dialogClassName="modal"
          show={showModal}
          onHide={() => setShowModal(false)}
          centered
          backdrop="static"
          keyboard={false}
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              {/* <Icon.Coin className="me-2" /> */}
              <h5 className="mb-0">Select Type of Excel Export</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              {/* call button */}
              <Col>
                <Button className="btn-submit  mx-auto" 
                  onClick={() => getCallLogReport("Call")}
                  disabled={loadingButton === "Dial Call"}>
                    { loadingButton === "Call" ? <Spinner variant="primary" />: "Call Logs Export" }
                </Button>
              </Col> 

            {/* call log button */}
              <Col>
                <Button className="btn-submit  mx-auto" 
                  onClick={() => getCallLogReport("Dial Call")}
                  disabled={loadingButton === "Call"}>
                  { loadingButton === "Dial Call" ? <Spinner variant="primary" />: "Dialed Call Logs Export" }
                </Button>
              </Col>

            </Row>
          </Modal.Body>
        </Modal>

        {/*Modal  */}
        <Modal
          dialogClassName="modal"
          show={showAllModal}
          onHide={() => setShowAllModal(false)}
          centered
          backdrop="static"
          keyboard={false}
          size="md"
        >
          <Modal.Header closeButton>
            <Modal.Title className="d-flex align-items-center">
              {/* <Icon.Coin className="me-2" /> */}
              <h5 className="mb-0">Select Type of Excel Export</h5>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Row>
              <Col>
                <Button className="btn-submit  mx-auto" 
                  onClick={() => getCallLogReportAllData("Call")}
                  disabled={loadingButton === "Dial Call"}>
                    { loadingButton === "Call" ? <Spinner variant="primary" /> : "Call Logs Export" }
                </Button>
              </Col>

              <Col>
                <Button className="btn-submit  mx-auto" 
                  onClick={() => getCallLogReportAllData("Dial Call")}
                  disabled={loadingButton === "Call"}>
                    { loadingButton === "Dial Call" ? <Spinner variant="primary" /> : "Dialed Call Logs Export"}
                </Button>
              </Col>

            </Row>
          </Modal.Body>
        </Modal>

      </Container>
    </div>
  )
}

export default InternalDevNew;